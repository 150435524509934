import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
// CUSTOM COMPONENTS
import { Paragraph, H6 } from 'components/typography';
import AvatarLoading from 'components/avatar-loading';
import FlexRowAlign from 'components/flexbox/FlexRowAlign';
import { getData } from 'api-handlers/apiFunctions';
import { useContext, useEffect } from 'react';
import { UserContext } from 'contexts/userContext';
import Skeleton from '@mui/material/Skeleton';



export default function UserAccount() {
  const { userProfilePic } = useContext(UserContext);
  const { userProfileInfo } = useContext(UserContext);


  return (
    <FlexRowAlign flexDirection="column" py={2}>
      <Badge color="primary">
      <AvatarLoading
            alt="user"
            percentage={60}
            src={userProfilePic}
            sx={{
              width: 50,
              height: 50
            }}
          />


      </Badge>

      <Box textAlign="center" pt={1} pb={2}>
        <H6 variant="outlined" label="Welcome Back" size="small" />

        <Paragraph fontSize={16} fontWeight={600} mt={1}>
          {userProfileInfo?.first_name}
        </Paragraph>

        <Paragraph fontSize={13} fontWeight={500} color="text.secondary" mt={0.25}>
          {userProfileInfo?.role}
        </Paragraph>
      </Box>
    </FlexRowAlign>
  );

}