
import React, { createContext, useState, useEffect } from 'react';
import { getData } from 'api-handlers/apiFunctions';
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userProfileInfo, setUserProfileInfo] = useState(() => {
    return JSON.parse(localStorage.getItem('userInfo')) || null
  });

  const [userProfilePic, setUserProfilePic] = useState(() => {
    return localStorage.getItem('userProfilePic') || '/static/user/user_logo_avatar.png';
  });



  const fetchUserInfo = async () => {
    const token = localStorage.getItem('authToken'); // Assuming the token is stored here

    if (!token) {
      console.log("No auth token found, skipping user info fetch.");
      return;
    }

    try {
      const result = await getData(process.env.REACT_APP_USER_INFO_ENDPOINT);
      if (result.success) {
        updateUserProfileInfo(result.data.user);
      }
    } catch (error) {
      console.log({ error });
    }
  };


  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     fetchUserInfo();
  //   }, 2 * 60 * 1000); // Runs every 3 minute
  
  //   return () => clearInterval(interval); 
  // }, []); 


  useEffect(() => {
    // Fetch on page load/re-load also
    fetchUserInfo(); 
  
    const interval = setInterval(() => {
      fetchUserInfo();
    }, 3 * 60 * 1000); // Runs every 3 minutes
  
    return () => clearInterval(interval); 
  }, []); 


  const updateUserProfilePic = (newProfilePic) => {
    setUserProfilePic(newProfilePic);
    localStorage.setItem('userProfilePic', newProfilePic);
  };

  const updateUserProfileInfo = (userInfo) => {
    setUserProfileInfo(userInfo);
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  };


  return (
    <UserContext.Provider value={{ userProfilePic, userProfileInfo, updateUserProfilePic, updateUserProfileInfo }}>
      {children}
    </UserContext.Provider>
  );
};
